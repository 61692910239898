import { PrivateRoute } from "components/PrivateRoute";
import FourOFour from "pages/404";
import Login from "pages/Login";
import { Root } from "pages/Root";
import {
  // BrowserRouter as BaseRouter,
  HashRouter as BaseRouter,
  Route,
  Routes,
} from "react-router-dom";

export const Router = () => {
  return (
    <BaseRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Root />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<FourOFour />} />
      </Routes>
    </BaseRouter>
  );
};

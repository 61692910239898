import {
  AuthenticationRequest,
  AuthenticationResult,
} from "@feathersjs/authentication/lib";
import { Application, Params } from "@feathersjs/feathers";
import { useCallback, useMemo, useState } from "react";
import { UserAttributes } from "types/global";

export const useAuthController = (props: {
  client: Application;
}): AuthControllerResult => {
  const { client } = props;
  const [account, setAccount] = useState<UserAttributes | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const isLoggedIn = useMemo(() => {
    return !!account;
  }, [account]);

  const authenticate = useCallback<AuthControllerResult["authenticate"]>(
    async (data, params) => {
      await setIsLoading(true);
      try {
        const res: AuthenticationResult = await client.authenticate(
          data,
          params
        );
        const account = res.account;
        await setAccount(account);
        await setIsLoading(false);
        return res;
      } catch (err: any) {
        await setAccount(null);
        await setIsLoading(false);
        throw new Error(err);
      }
    },
    [client]
  );

  const reAuthenticate = useCallback<AuthControllerResult["reAuthenticate"]>(
    async (force?, strategy?) => {
      await setIsLoading(true);
      try {
        const res = await client.reAuthenticate(force, strategy);
        const user = res.user;
        await setIsLoading(false);
        await setAccount(user);
        return res;
      } catch (err: any) {
        await setIsLoading(false);
        await setAccount(null);
        throw new Error(err);
      }
    },
    [client]
  );

  const logout = useCallback<AuthControllerResult["logout"]>(async () => {
    await setIsLoading(true); 
    try {
      const res = await client.logout();
      await setAccount(null);
      await setIsLoading(false);
      return res;
    } catch (err: any) {
      await setIsLoading(false);
      throw new Error(err);
    }
  }, [client]);

  // useEffect(() => {
  //   if (account !== null) return;
  //   if (!client) return;
  //   console.log(account);
  //   const fetch = async () => {
  //     setIsLoading(true);
  //     try {
  //       const res = await client.reAuthenticate();
  //       const account = res.account;
  //       await setAccount(account);
  //     } catch (err) {
  //       console.error(err);
  //       await setAccount(null);
  //     }
  //     setIsLoading(false);
  //   };
  //   fetch();
  // }, []);

  return {
    account,
    isLoggedIn,
    isLoading,

    authenticate: authenticate,
    reAuthenticate: reAuthenticate,
    logout: logout,
  };
};

export interface AuthControllerResult {
  account: UserAttributes | null;
  isLoggedIn: boolean;
  isLoading: boolean;

  authenticate: (
    authentication?: AuthenticationRequest,
    params?: Params
  ) => Promise<AuthenticationResult>;
  reAuthenticate: (
    force?: boolean,
    strategy?: string
  ) => Promise<AuthenticationResult>;
  logout(): Promise<AuthenticationResult | null>;
}

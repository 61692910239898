import { AccessPoint } from "tabler-icons-react";
import { Group, ThemeIcon, Title } from "@mantine/core";

const FourOFour = () => {
  return (
    <Group
      sx={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <ThemeIcon>
        <AccessPoint />
      </ThemeIcon>
      <Title>404</Title>
    </Group>
  );
};
export default FourOFour;

import {
  Button,
  Center,
  Divider,
  Drawer,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useAuth } from "hooks/useAuth";
import { FC, ReactElement, useCallback } from "react";
import { Logout } from "tabler-icons-react";

interface props {
  opened: boolean;
  onClose: () => void;
}

const ProfileSider: FC<props> = ({ opened, onClose }): ReactElement => {
  const { colors, spacing } = useMantineTheme();
  const { account, logout, isLoading } = useAuth();

  const logOut = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Drawer
      padding="lg"
      title="Profile"
      size="md"
      opened={opened}
      onClose={onClose}
    >
      <Center sx={{ flexDirection: "column" }}>
        <Title order={5}>{account?.full_name}</Title>
        <Text
          sx={{
            color: colors.gray[6],
            marginTop: spacing.md,
            marginBottom: spacing.xl,
          }}
        >
          {account?.username}
        </Text>
        <Divider sx={{ margin: "0 20px" }} />
        <Button
          loading={isLoading}
          color="red"
          variant="light"
          leftIcon={<Logout />}
          fullWidth
          onClick={logOut}
        >
          Logout
        </Button>
      </Center>
    </Drawer>
  );
};

export default ProfileSider;

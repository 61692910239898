import {
  ActionIcon,
  Box,
  Button,
  Center,
  Image,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import { Eye } from "tabler-icons-react";
import * as yup from "yup";
import { useForm, yupResolver } from "@mantine/form";
import { useAuth } from "hooks/useAuth";
import { AuthenticationResult } from "@feathersjs/authentication";
import { useErrorCatcher } from "hooks/useErrorCatcher";
import { FeathersError } from "types/global";
import { Navigate, useNavigate } from "react-router-dom";

const initialValues = {
  username: "",
  password: "",
};

const validationSchema = yup.object().shape({
  username: yup.string().required("Please input the username"),
  password: yup.string().required("Please input the password"),
});

const Layout: FC = (): ReactElement => {
  const [passwordFieldType, setPasswordFieldType] = useState<
    "password" | "text"
  >("password");
  const theme = useMantineTheme();
  const form = useForm({
    schema: yupResolver(validationSchema),
    initialValues,
  });
  const navigate = useNavigate();
  const { authenticate, isLoggedIn } = useAuth();
  const [loading, toggleLoading] = useState<boolean>(false);
  const { errorCatcher } = useErrorCatcher();

  const togglePasswordFieldType = useCallback(() => {
    setPasswordFieldType((type) => (type === "password" ? "text" : "password"));
  }, []);

  const passwordRightSection = useMemo(
    () => (
      <ActionIcon
        color={passwordFieldType === "password" ? "gray" : "green"}
        onClick={togglePasswordFieldType}
        variant="transparent"
      >
        <Eye size={24} />
      </ActionIcon>
    ),
    [togglePasswordFieldType, passwordFieldType]
  );

  const onSubmit = useCallback(
    (val: typeof initialValues) => {
      toggleLoading(true);
      authenticate({
        strategy: "local",
        ...val,
      })
        .then((resp: AuthenticationResult | FeathersError) => {
          console.log(resp);
          toggleLoading(false);
          if ("code" in resp) {
            errorCatcher(resp);
          } else {
            navigate("/");
          }
        })
        .catch((e: any) => {
          toggleLoading(false);
          errorCatcher(e);
        });
    },
    [errorCatcher, authenticate, navigate]
  );

  return isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <Center
      sx={() => ({
        width: "100%",
        height: "100%",
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.white,
          padding: theme.spacing.md,
          borderRadius: theme.spacing.md,
          width: "35%",
        })}
      >
        <Image
          alt="Logo"
          src={require("assets/img/katedu.png")}
          sx={{ width: "75%", margin: "35px auto", display: "block" }}
        />
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            sx={{
              margin: "12px 0",
              color: theme.colors.green[3],
            }}
            placeholder="Username"
            radius="xl"
            size="lg"
            name="username"
            autoComplete="username"
            {...form.getInputProps("username")}
          />
          <TextInput
            sx={{
              margin: "12px 0",
              color: theme.colors.green[3],
            }}
            placeholder="Password"
            name="password"
            radius="xl"
            size="lg"
            autoComplete="current-password"
            type={passwordFieldType}
            rightSection={passwordRightSection}
            {...form.getInputProps("password")}
          />
          <Box
            sx={(theme) => ({
              background: theme.colors.green[3],
              padding: 4,
              borderRadius: 14,
              width: 35,
              margin: "60px auto",
            })}
          />
          <Button
            size="lg"
            sx={(theme) => ({
              width: "100%",
              background: theme.colors.green[3],
              "&:hover": {
                background: theme.colors.green[4],
              },
              borderRadius: 25,
            })}
            type="submit"
            loading={loading}
          >
            Login
          </Button>
        </form>
      </Box>
    </Center>
  );
};

export default Layout;

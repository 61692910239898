import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import { AppShell, Navbar, useMantineTheme } from "@mantine/core";
import NavbarLink from "./NavbarLink";
import {
  Building,
  Calendar,
  CurrentLocation,
  Dashboard,
  Settings,
  TableExport,
  Users,
} from "tabler-icons-react";
import { UserCard } from "./UserCard";
import DashboardRoute from "./DashboardRoute";
import ProfileSider from "./ProfileSider";

interface NAVLINK {
  label: string;
  icon: ReactElement;
  color: string;
  route: string;
}

const Layout: FC = (): ReactElement => {
  const theme = useMantineTheme();
  const [drawer, toggleDrawer] = useState<boolean>(false);

  const navLinks = useMemo<NAVLINK[]>(() => {
    const { colors } = theme;
    let links: NAVLINK[] = [
      {
        label: "Dashbord",
        icon: <Dashboard size={16} />,
        color: colors.green[3],
        route: "/",
      },
      {
        label: "Deartments",
        icon: <Building color={colors.blue[5]} size={16} />,
        color: colors.yellow[1],
        route: "/departments",
      },
      {
        label: "Users",
        icon: <Users color={colors.grape[5]} size={16} />,
        color: colors.yellow[1],
        route: "/users",
      },
      {
        label: "Work Order",
        icon: <Settings color={colors.teal[5]} size={16} />,
        color: colors.grape[1],
        route: "/work-order",
      },
      {
        label: "Schedules",
        icon: <Calendar color={colors.indigo[5]} size={16} />,
        color: colors.indigo[1],
        route: "/schedules",
      },
      {
        label: "Spot Attendance",
        icon: <CurrentLocation color={colors.gray[7]} size={16} />,
        color: colors.indigo[1],
        route: "/spot-attendance",
      },
      {
        label: "Attendance Report",
        icon: <TableExport color={colors.lime[5]} size={16} />,
        color: colors.lime[1],
        route: "/export",
      },
      {
        label: "Job Report",
        icon: <TableExport color={colors.lime[5]} size={16} />,
        color: colors.lime[1],
        route: "/job-report",
      },
      {
        label: "Settings",
        icon: <Settings color={colors.teal[5]} size={16} />,
        color: colors.grape[1],
        route: "/settings",
      },
    ];
    return links;
  }, [theme]);

  const openDrawer = useCallback(() => {
    toggleDrawer(true);
  }, []);

  const closeDrawer = useCallback(() => {
    toggleDrawer(false);
  }, []);

  return (
    <AppShell
      styles={{
        main: {
          height: "100%",
        },
      }}
      fixed
      navbar={
        <Navbar p="md" width={{ sm: 200, lg: 220 }}>
          <UserCard onPress={openDrawer} />
          {navLinks.map(({ label, icon, color, route }) => (
            <NavbarLink
              key={route}
              label={label}
              icon={icon}
              color={color}
              route={route}
            />
          ))}
        </Navbar>
      }
    >
      <DashboardRoute />
      <ProfileSider onClose={closeDrawer} opened={drawer} />
    </AppShell>
  );
};

export default Layout;

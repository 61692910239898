import { Center, Loader } from "@mantine/core";
import { useAuth } from "hooks/useAuth";
import { ReactElement, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export const PrivateRoute = (props: PrivateRouteProps): ReactElement => {
  const { children } = props;
  const { isLoggedIn, reAuthenticate } = useAuth();

  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  useEffect(() => {
    const fetch = async () => {
      if (isLoggedIn) return;
      console.count("re:AUTH");
      try {
        await reAuthenticate();
        await setIsAuth(true);
      } catch (err: any) {
        console.error(err.message);
        await setIsAuth(false);
      }
    };
    fetch();
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isAuth === null)
    return (
      <Center sx={{ position: "fixed", inset: 0 }}>
        <Loader size="lg" />
      </Center>
    );
  if (isAuth === false) return <Navigate to="/login" />;
  return children;
};

interface PrivateRouteProps {
  children: ReactElement;
}

import { FC, ReactElement } from "react";
import Layout from "./Layout";
import Helmet from "react-helmet";

const Login: FC = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>Login - Katedu</title>
      </Helmet>
      <Layout />
    </>
  );
};

export default Login;

import FeathersAuth from "@feathersjs/authentication-client";
import FeathersSocketIOClient from "@feathersjs/socketio-client";
import Feathers, { Application } from "@feathersjs/feathers";
import io from "socket.io-client";
import { useEffect, useState } from "react";

const { REACT_APP_BASEURL }: NodeJS.ProcessEnv = process.env;

const host = new URL(REACT_APP_BASEURL as string);

const feathers: Application = Feathers();
const socket: SocketIOClient.Socket = io(`${REACT_APP_BASEURL}`);

feathers.configure(FeathersSocketIOClient(socket, { timeout: 60000 }));
feathers.configure(
  FeathersAuth({
    // storage: window.localStorage,
    storageKey: "accessToken",
    // jwtStrategy: 'local'
  })
);

export const useClientController = (): ClientControllerResult => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const listener = [() => setIsConnected(true), () => setIsConnected(false)];
    socket.on("connect", listener[0]);
    socket.on("disconnect", listener[1]);
    return () => {
      socket.off("connect", listener[0]);
      socket.off("disconnect", listener[1]);
    };
  }, []);

  return {
    client: feathers,
    socket,
    host,

    isConnected,
  };
};

export interface ClientControllerResult {
  client: Application;
  socket: SocketIOClient.Socket;
  host: URL;

  isConnected: boolean;
}

import { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";
import Layout from "./Layout";

const Dashboard: FC = (): ReactElement => {
  return (
    <>
    <Helmet>
      <title>Dashboard - Katedu</title>
    </Helmet>
      <Layout />
    </>
  );
};

export default Dashboard;

import { Center, Loader, Title, useMantineTheme } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { FC, ReactElement, lazy, Suspense, useMemo } from "react";
import { Route, Routes } from "react-router-dom";

const ExportDataByUser = lazy(() => import("pages/ExportDataByUser"));
const ExportDataByUserReport = lazy(
  () => import("pages/ExportDataByUser/Report")
);
const Home = lazy(() => import("pages/Home"));
const Users = lazy(() => import("pages/Users"));
const WorkOrder = lazy(() =>
  import("pages/WorkOrder").then((m) => ({ default: m.WorkOrder }))
);
const ExportData = lazy(() => import("pages/ExportData"));
const Departments = lazy(() => import("pages/Departments"));
const Settings = lazy(() => import("pages/Settings"));
const Schedules = lazy(() => import("pages/Schedules"));
const Spots = lazy(() => import("pages/Spots"));

const DashboardRoute: FC = (): ReactElement => {
  const { width, height } = useViewportSize();
  const { primaryColor } = useMantineTheme();
  const fallbackComp = useMemo(
    () => (
      <Center sx={{ height, width: width - 250 }}>
        <Loader color={primaryColor} size="lg" />
        <Title sx={{ marginLeft: 15 }}>Loading page</Title>
      </Center>
    ),
    [height, primaryColor, width]
  );
  return (
    <Suspense fallback={fallbackComp}>
      <Routes>
        <Route index element={<Home />} />
        <Route path="job-report/:userId" element={<ExportDataByUserReport />} />
        <Route path="job-report" element={<ExportDataByUser />} />
        <Route path="export" element={<ExportData />} />
        <Route path="departments" element={<Departments />} />
        <Route path="settings" element={<Settings />} />
        <Route path="schedules" element={<Schedules />} />
        <Route path="users" element={<Users />} />
        <Route path="spot-attendance" element={<Spots />} />
        <Route path="work-order" element={<WorkOrder />} />
      </Routes>
    </Suspense>
  );
};

export default DashboardRoute;
